import React from 'react';
import { Container, Row, Col, Card, Button, Image } from 'react-bootstrap';
import './MainPage.css';

const MainPage: React.FC = () => {
  return (
    <Container fluid>
      <Row className="bg-primary text-white">
        <Col className="d-flex align-items-center justify-content-center py-5">
          <div>
            <h1>Welcome to My Website</h1>
            <h2>I'm John Doe, a web developer</h2>
          </div>
        </Col>
      </Row>

      <Row className="mt-5 justify-content-center">
        <Col xs={12} md={6}>
          <Card className="text-center shadow">
            <Card.Header>About Me</Card.Header>
            <Card.Body>
              <Image src="https://via.placeholder.com/150" roundedCircle className="mb-3" />
              <Card.Title>John Doe</Card.Title>
              <Card.Text>
                Hi, my name is John Doe, and I'm a web developer specializing in React and TypeScript.
                I have experience working with a variety of web technologies and enjoy creating beautiful, responsive, and user-friendly websites.
                When I'm not coding, I enjoy hiking, photography, and spending time with my family.
              </Card.Text>
              <Button variant="primary" href="mailto:johndoe@example.com">Contact Me</Button>
            </Card.Body>
            <Card.Footer className="text-muted">
              <a href="https://www.linkedin.com/in/johndoe" target="_blank" rel="noopener noreferrer">Connect with me on LinkedIn</a>
            </Card.Footer>
          </Card>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          <h2 className="text-center">My Skills</h2>
          <Row className="mt-3">
            <Col xs={12} md={4} className="mb-4">
              <Card className="h-100 shadow">
                <Card.Body>
                  <Card.Title>Frontend Development</Card.Title>
                  <Card.Text>
                    HTML, CSS, JavaScript, React, TypeScript, Redux, and more.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4} className="mb-4">
              <Card className="h-100 shadow">
                <Card.Body>
                  <Card.Title>Backend Development</Card.Title>
                  <Card.Text>
                    Node.js, Express, Django, Flask, GraphQL, and more.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4} className="mb-4">
              <Card className="h-100 shadow">
                <Card.Body>
                  <Card.Title>DevOps & CI/CD</Card.Title>
                  <Card.Text>
                    Docker, Kubernetes, Jenkins, Git, and more.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default MainPage;
